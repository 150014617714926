.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container { 
  /* width: 100%/; */
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.inner-page {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

header {
  background: #eee;
  padding-top: 20px;
  padding-bottom: 20px;
}

header .logo img{
  width: 70px;
}

.navigation { 
  background: #03A64A;
  color:#fff;
}

.navigation ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation ul li { 
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.navigation ul li a {
  color: #fff;
  text-decoration: none;
}

.navigation ul li:hover {
  background-color: #007231;
}

footer {
  background: #eee;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: auto;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
}

.form-big {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
}

.form-input {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 80%;
}

.form-input label {
  padding-bottom: 10px;
  text-align: left;
}

.form-input input{
  height: 30px;
  width: 100%;
}

.login-form button {
  height: 30px;
  width: 80%;
  background: #03A64A;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #ccc;
  padding: 10px;
}

.inner-page__content {
  padding: 20px;
  max-width: 1200px;
}

.actionButton {
  background: #03A64A;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.margin-left {
  margin-left: 10px;
}

.margin-bottom {
  margin-bottom: 10px;
}

.margin-top {
  margin-top: 10px;
}

.expired {
  background-color: red;
  color: #fff;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
}

.form-group label {
  padding-bottom: 10px;
  text-align: left;
}

.form-group input{
  height: 30px;
  width: 100%;
}

.form-group select{
  height: 30px;
  width: 100%;
}

.form-group textarea{
  height: 100px;
  width: 100%;
}

.modal-button {
  height: 30px;
  width: 100%;
  background: #03A64A;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.register {
  height: 30px;
  background: #03A64A;
  color: #fff;
  border: none;
  cursor: pointer;
}

.error {
  color: red;
}

.message {
  background: #30e980;
  color: #000;
  padding: 10px;
  margin-bottom: 20px;
  width: 70%;
  margin-top: 20px;
}

.price-table {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  gap: 20px;
}

.price-option {
  border: 2px solid #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s, transform 0.3s;
  width: 30%;
}

.price-option:hover {
  border-color: #3498db;
  transform: scale(1.05);
}

.selected {
  border-color: #3498db;
}

.selected-option-details {
  margin-top: 20px;
  margin-bottom: 20px;

}

.renew_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  gap: 30px;
}

.renew_item__data {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
}

.renew_item img {
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
}

@media (max-width: 1200px) {
  .inner-page__content {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .inner-page__content {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .inner-page__content {
    width: 100%;
  }
}


